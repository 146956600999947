import { Activity } from "./activity";
import { Destination } from "./destination";
import { FilterDuration } from "./filterDuration";
import { FilterStartDeparture } from "./filters/filterStartDeparture";
import { FilterYear } from "./filters/filterYear";
import { Structure } from "./structure";
import { Thematic } from "./thematic";

export class Filters {
    public keywords: string;
    public startDepartureList: Array<FilterStartDeparture>;
    public startDepartureDate: Date;
    public thematics: Array<Thematic>;
    public yearsList: Array<string>;
    public durationsList: Array<FilterDuration>;
    public activitiesList: Array<Activity>;
    public structuresList: Array<Structure>;
    public destinationsList: Array<Destination>;
    public allDestinationsList: Array<Destination>;
    public startCitiesList: Array<string>;
    public order: string;

    constructor(keywords?: string, startDepartureList?: Array<FilterStartDeparture>, startDepartureDate?: Date,
        thematics?: Array<Thematic>, yearsList?: Array<string>, durationsList?: Array<FilterDuration>,
        activitiesList?: Array<Activity>, structuresList?: Array<Structure>, destinationsList?: Array<Destination>,
        allDestinationsList?: Array<Destination>, startCitiesList?: Array<string>)

    constructor(keywords: string, startDepartureList: Array<FilterStartDeparture>, startDepartureDate: Date,
        thematics: Array<Thematic>, yearsList: Array<string>, durationsList: Array<FilterDuration>,
        activitiesList: Array<Activity>, structuresList: Array<Structure>, destinationsList: Array<Destination>,
        allDestinationsList: Array<Destination>, startCitiesList: Array<string>) {
        this.keywords = keywords;
        this.startDepartureList = startDepartureList;
        this.startDepartureDate = startDepartureDate;
        this.thematics = thematics;
        this.yearsList = yearsList;
        this.durationsList = durationsList;
        this.activitiesList = activitiesList;
        this.structuresList = structuresList;
        this.destinationsList = destinationsList;
        this.allDestinationsList = allDestinationsList;
        this.startCitiesList = startCitiesList;
    }
}