import { Injectable } from "@angular/core";
import { HttpRequestMethods } from "../shared/functions/http-request-methods.class";
import { CreationObject } from "../shared/functions/creation-object";
import { Tag } from "src/dto/tag";
import { FilterDuration } from "src/dto/filterDuration";

@Injectable()
export class FilterDurationService extends HttpRequestMethods {

    async listFilterDuration() {
        var rslt = await this.get("filterduration");
        var rsltTab = rslt as string[];

        var filterDurationsList = new Array<FilterDuration>();
        rsltTab.forEach(filterDuration => {
            filterDurationsList.push(CreationObject.CreateFilterDuration(filterDuration));
        })
        return filterDurationsList;
    }
}