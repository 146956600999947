import { Component, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { ErrorMessage } from 'src/dto/errorClass/error';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user-service';
import { ErrorModalComponent } from 'src/shared/error-modal/error-modal.component';

@Component({
    selector: 'forget-password-dialog',
    templateUrl: './forget-password.html',
    styleUrls: ['./forget-password.scss']
})

@Injectable()
export class ForgetPasswordDialog {
    isLoading = false;

    email: string;

    formGroup = new FormGroup({
        email: new FormControl('', [Validators.email, Validators.required]),
    });

    constructor(
        public currentDialog: MatDialogRef<ForgetPasswordDialog>,
        private _snackBar: MatSnackBar,
        private userSvc: UserService
    ) {}

    async save(){
        if(this.formGroup.invalid){
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Veuillez remplir tous les champs")
            });
        }

        this.isLoading = true;
        try{
            await this.userSvc.forgetPassword(this.email);
            
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(1, "Action réalisée avec succés !")
            });

            this.currentDialog.close();
        }
        catch(ex){
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, ex.error.code)
            });
        }
        this.isLoading = false;
    }
}