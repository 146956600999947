import { I } from '@angular/cdk/keycodes';
import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Filters } from 'src/dto/filters';
import { FilterStartDeparture } from 'src/dto/filters/filterStartDeparture';

export interface DialogData {
    listStartDepartureFilters: Array<FilterStartDeparture>;
    dateSelected: Date;
}

@Component({
    selector: 'filter-date-departure',
    templateUrl: './date-departure.html',
    styleUrls: ['./date-departure.scss']
})

@Injectable()
export class FilterDateDepartureDialog {
    
    categorySelected = "MONTH";
    today = new Date();

    constructor(
        public currentDialog: MatDialogRef<FilterDateDepartureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit(){

    }

    goValidate(){
        this.currentDialog.close(this.data);
    }

    changeCategory(category: string){
        this.data.listStartDepartureFilters.forEach(x => {
            if(x.category != category)
                x.isSelected = false;
        })

        if(category != "DATE")
            this.data.dateSelected = null;

        this.categorySelected = category;
    }
}