import { Structure } from './structure';
import { User } from "./user";

export class sideMenu {
  public icon: string;
	public displayValue: string;
	public value: string;
  public rights: Array<string>;

  
	constructor(displayValue?:string, value?:string, rights?:Array<string>)

	constructor(displayValue:string, value:string, rights?:Array<string>) {
		this.displayValue = displayValue;
		this.value = value;
    this.rights = rights;

  }

  static PROFIL = new sideMenu("Profil","PROFIL",[User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR,User.PROFIL_PARENT]);
  static STAYS = new sideMenu("Mes séjours", "STAYS",[User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR]);
  static MESSAGES = new sideMenu("Messagerie", "MESSAGES",[User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR,User.PROFIL_PARENT]);
    
  public static GetMenu(): Array<sideMenu>{
    return  [
      this.PROFIL,
      this.STAYS,
      //INVISIBLE
      this.MESSAGES
      ];
  }
}
