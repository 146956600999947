import { Component, Injectable, OnInit } from '@angular/core';
import { FilterOrder } from 'src/dto/filters/filterOrder';
import { Stay } from 'src/dto/stay';
import { StayService } from 'src/services/stay-service';
import { SharedSettings } from 'src/shared/functions/shared-settings';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'stay-list',
    templateUrl: './stay-list.view.html',
    styleUrls: ['./stay-list.view.scss']
})

@Injectable()
export class StayListView {
    isLoading = false;

    staysList = new Array<Stay>();
    displayStyle = "BLOCK";

    filterOrder : string;
    filterOrderList = new Array<FilterOrder>();

    constructor(
        public staySvc: StayService
        ) {}

    async ngOnInit() {
        this.filterOrderList = SharedSettings.listOrderFilters();
        
        if(!isNullOrUndefined(JSON.parse(sessionStorage.getItem("FILTERS")))){
            var filters = JSON.parse(sessionStorage.getItem("FILTERS"));
            this.filterOrder = filters.order;
        }

        await this.loadingStaysList();

        


        // else{
        //     this.staysList = await this.staySvc.listStays();
        // }
    }

    async loadingStaysList(){
        this.isLoading = true;

        var filters = JSON.parse(sessionStorage.getItem("FILTERS"));
        filters.order = this.filterOrder;
        sessionStorage.setItem("FILTERS", JSON.stringify(filters));

        // if(!isNullOrUndefined(JSON.parse(sessionStorage.getItem("FILTERS")) && !isNullOrUndefined(this.filterOrder))){
            this.staysList = await this.staySvc.staysFiltered(JSON.parse(sessionStorage.getItem("FILTERS")));
            // sessionStorage.setItem("FILTERS", null);
        // }
        // else{
        //     this.staysList = await this.staySvc.listStays();
        // }
        this.isLoading = false;
    }
}