import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { sideMenu } from 'src/dto/sideMenu';
import { Structure } from 'src/dto/structure';
import { User } from 'src/dto/user';
import { UtilsFunctions } from 'src/shared/functions/utils-functions';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.html',
  styleUrls: ['./side-menu.scss']
})
export class SideMenuView implements OnInit {
  @Input() currentUser: User;
  @Input() currentStructure: Structure;
  @Input() itemSelected: sideMenu;

  @Output() outputChangeMenu: EventEmitter<sideMenu> = new EventEmitter<sideMenu>();
  @Input() menu: Array<sideMenu>;
 
 
  itemArray = [
    { displayValue: 'Profil', value: 'PROFIL', rights: [User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR,User.PROFIL_PARENT] },
    { displayValue: 'Mes séjours', value: 'STAYS', rights: [User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR] },
    { displayValue: 'Messagerie', value: 'MESSAGES', rights: [User.PROFIL_DIRECTOR, User.PROFIL_COLLABORATEUR,User.PROFIL_PARENT] },
];

selectedMenu = "PROFIL";

  constructor() { }

  ngOnInit() {
    this.menu = sideMenu.GetMenu();
  }

 
}
