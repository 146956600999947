import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Structure } from 'src/dto/structure';

export interface DialogData {
    structuresList: Array<Structure>;
}

@Component({
    selector: 'filter-structure',
    templateUrl: './structure.html',
    styleUrls: ['./structure.scss']
})

@Injectable()
export class FilterStructureDialog {

    count = 0;
    text: string = "";
    searchText: string;

    optionList: Array<Structure>;

    constructor(
        public currentDialog: MatDialogRef<FilterStructureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
        this.optionList = this.data.structuresList;
        this.checkTextSelected();
    }

    checkTextSelected() {
        this.text = "";
        var listFiltered = this.data.structuresList.filter(x => x.isSelected == true)
        this.count = listFiltered.length;
        
        listFiltered.forEach(x => {
            if(x.name != listFiltered[0].name)
                this.text += ", ";
            
            this.text += x.name;
        })
    }

    filterList() {
        this.optionList = this.data.structuresList;
        this.optionList = this.optionList.filter(structure => structure.name.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    goValidate() {
        this.currentDialog.close(this.data);
    }

    selectStructure(structureId: number){
        this.data.structuresList.forEach(structure => {
            if(structure.id == structureId)
                structure.isSelected = !structure.isSelected
        });


        this.checkTextSelected();
    }

    deleteAll() {
        this.data.structuresList.forEach(structure => {
            structure.isSelected = false;
        });

        this.checkTextSelected();
    }
}