export class Destination {
    public label: string;
    public type: string;
    public postalCode : string;
	public source : string;
    public isCheck: boolean;
	public isExpand: boolean;

    constructor(label?: string, type?: string, postalCode?: string, source?: string,
		 isCheck?: boolean, isExpand?: boolean)

	constructor(label: string, type: string, postalCode: string, source: string,
		isCheck: boolean, isExpand: boolean) {
		this.label = label;
		this.type = type;
		this.postalCode = postalCode;
		this.source = source;
		this.isCheck = isCheck;
		this.isExpand = isExpand;
	}
}