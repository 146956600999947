import { Component, Injectable, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { UtilsFunctions } from '../../shared/functions/utils-functions';
import { AuthentificationDialog } from 'src/dialogs/authentification/authentification.dialog';
import { RegisterDialog } from 'src/dialogs/register/register.dialog';
import { WelcomeDialog } from 'src/dialogs/welcome/welcome.dialog';
import { ForgetPasswordDialog } from 'src/dialogs/password/forget/forget-password';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

@Injectable()
export class HeaderComponent {
    @Input() currentUser;
    @Input() currentStructure;

    displayMenu = false;

    constructor(public dialog: MatDialog, private router: Router) { }

    ngOnInit() {
        // if (this.currentUser == null)
        //     this.router.navigate(['home']);
    }

    logIn(): void {
        const dialogCard = this.dialog.open(AuthentificationDialog, {
            width: '70%',
            height: '80%',
            panelClass: 'mat-dialog-any-padding',
            autoFocus: false
        });

        dialogCard.afterClosed().subscribe(rslt => {
            if(rslt == "FORGET_PASSWORD")
                this.forgetPassword();
            if(rslt == "CONNECTED")
                this.refreshUserConnected();
        });
    }

    register() {
        const dialogCard = this.dialog.open(RegisterDialog, {
            width: '80%',
            height: '90%',
            panelClass: 'mat-dialog-any-padding',
            autoFocus: false
        });

        dialogCard.afterClosed().subscribe(async rslt => {
            if (!isNullOrUndefined(rslt)) {
                const dialogCard = this.dialog.open(WelcomeDialog, {
                    width: '70rem',
                    height: '30rem',
                    panelClass: 'mat-dialog-information',
                    autoFocus: false
                });
            }
        });
    }

    forgetPassword(){
        const dialogCard = this.dialog.open(ForgetPasswordDialog, {
            width: '30%',
            autoFocus: false
        });
    }

    // toRegister(): void {
    //     const dialogCard = this.dialog.open(AuthentificationDialogComponent, {
    //         width: '50%',
    //         height: '50%',
    //         data: { item: "Registration" },
    //         autoFocus: false
    //     });

    //     dialogCard.afterClosed().subscribe(rslt => {
    //     });
    // }

    logOut() {
        sessionStorage.setItem("TOKEN", null);
        sessionStorage.setItem("USER", null);
        sessionStorage.setItem("STRUCTURE", null);
        sessionStorage.setItem("HOMECATEGORY", "");
        this.router.navigate(["/"]);
        this.refreshUserConnected();
    }

    refreshUserConnected() {
        this.currentUser = UtilsFunctions.SessionGetCurrentUser();
    }
}