import { Component, Injectable, ViewChild, ElementRef, OnInit, Input, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import * as L from 'leaflet';
import { Stay } from "src/dto/stay";
import { environment } from "src/environments/environment";
import { isNullOrUndefined } from "util";
import { User } from "../../../dto/user";

@Component({
    selector: 'map-item',
    templateUrl: './map-item.component.html',
    styleUrls: ['./map-item.component.scss']
})

@Injectable()
export class MapItemComponent {
    @Input() inputStay: Stay;

    constructor(private router: Router) { }


    ngOnInit() {
    }

    showStay(){
        this.router.navigate([`stay/${this.inputStay.id}`])
    }
    minimumPrice() {
        var min = this.inputStay.furtherInformationsList[0].price;
        if(this.inputStay.furtherInformationsList.length > 1){
            this.inputStay.furtherInformationsList.forEach(s => {
                if (min > s.price)
                min = s.price;
            })
        }
        return min;
    }
}
