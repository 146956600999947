export class FilterOrder {
    public value: string;
    public displayValue: string;

    constructor(value?: string, displayValue?: string)

    constructor(value: string, displayValue: string) {
        this.value = value;
        this.displayValue = displayValue;
    }
}