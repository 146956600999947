export class Structure {
	public id: number;
	public email: string;
	public street: string;
	public city: string;
	public postCode: string;
	public department: string;
	public state: string;
	public country: string;
	public phone: number;
	public longitude: string;
	public latitude: string;
	public siret: string;
	public name: string;
	public description: string;
	public website: string;
	public labels: string;
	public isActive: boolean;
	public isSelected: boolean;

	constructor(id?: number, email?: string, street?: string, city?: string,
		postCode?: string, department?: string, state?: string, country?: string,  phone?: number, longitude?: string,
		latitude?: string, siret?: string, name?: string, description?: string,
		website?: string, labels?: string, isActive?: boolean, isSelected?: boolean)

	constructor(id: number, email: string, street: string, city: string,
		postCode: string, department: string, state: string, country: string, phone: number,
		longitude: string, latitude: string, siret: string, name: string, description: string,
		website: string, labels: string, isActive: boolean, isSelected: boolean) {
		this.id = id;
		this.email = email;
		this.street = street;
		this.city = city;
		this.postCode = postCode;
		this.department = department;
		this.state = state;
		this.country = country;
		this.phone = phone;
		this.longitude = longitude;
		this.latitude = latitude;
		this.siret = siret;
		this.name = name;
		this.description = description;
		this.website = website;
		this.labels = labels;
		this.isActive = isActive;
		this.isSelected = isSelected;
	}
}
