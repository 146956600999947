import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Activity } from 'src/dto/activity';

export interface DialogData {
    activitiesList: Array<Activity>;
}

@Component({
    selector: 'filter-activity',
    templateUrl: './activity.html',
    styleUrls: ['./activity.scss']
})

@Injectable()
export class FilterActivityDialog {

    count = 0;
    text: string = "";
    searchText: string;

    optionList: Array<Activity>;

    constructor(
        public currentDialog: MatDialogRef<FilterActivityDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
        this.optionList = this.data.activitiesList;
        this.checkTextSelected();
    }

    checkTextSelected() {
        this.text = "";
        var listFiltered = this.data.activitiesList.filter(x => x.isSelected == true)
        this.count = listFiltered.length;

        listFiltered.forEach(x => {
            if (x.label != listFiltered[0].label)
                this.text += ", ";

            this.text += x.label;
        })
    }

    filterList() {
        this.optionList = this.data.activitiesList;
        this.optionList = this.optionList.filter(activity => activity.label.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    goValidate() {
        this.currentDialog.close(this.data);
    }

    selectActivity(activityId: number) {
        this.data.activitiesList.forEach(activity => {
            if (activity.id == activityId)
                activity.isSelected = !activity.isSelected
        });

        this.optionList = this.data.activitiesList;

        this.checkTextSelected();
    }

    deleteAll() {
        this.data.activitiesList.forEach(activity => {
            activity.isSelected = false;
        });

        this.optionList = this.data.activitiesList;

        this.checkTextSelected();
    }
}