import { X, Y } from '@angular/cdk/keycodes';
import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Destination } from 'src/dto/destination';
import { StayService } from 'src/services/stay-service';
import { UtilsFunctions } from 'src/shared/functions/utils-functions';

export interface DialogData {
    displayList: Array<Destination>;
}

@Component({
    selector: 'filter-destination',
    templateUrl: './destination.html',
    styleUrls: ['./destination.scss']
})

@Injectable()
export class FilterDestinationDialog {
    isLoading = false;
    ORDER_DESTINATIONS = ["COUNTRY", "STATE", "CONTY", "CITY"];

    // destinationsList = new Array<Destination>();
    countriesList = new Array<Destination>();
    statesList = new Array<Destination>();
    contiesList = new Array<Destination>();
    citiesList = new Array<Destination>();

    constructor(
        private staySvc: StayService,
        public currentDialog: MatDialogRef<FilterDestinationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    async ngOnInit() {
        console.log(this.data);
        this.countriesList = this.data.displayList.filter(x => x.type == "COUNTRY");
        this.statesList = this.data.displayList.filter(x => x.type == "STATE");
        this.contiesList = this.data.displayList.filter(x => x.type == "CONTY");
        this.citiesList = this.data.displayList.filter(x => x.type == "CITY");
    }

    async loadDestinationsList(source: string, target: string) {
        this.isLoading = true;
        var list = await this.staySvc.listDestinations(source, target);
        this.isLoading = false;
        return list;
    }

    async touchItem(destination: Destination) {
        destination.isCheck = !destination.isCheck;
        var target = this.ORDER_DESTINATIONS[this.ORDER_DESTINATIONS.indexOf(destination.type) + 1];

        switch (destination.type) {
            case "COUNTRY":
                if (destination.isCheck){
                    this.statesList = this.statesList.concat(await this.loadDestinationsList(destination.label, target));
                    this.statesList.sort((a,b) => a.label.localeCompare(b.label));
                }
                else this.deleteFromSource(destination.type, destination.label);
                break;
            case "STATE":
                if (destination.isCheck){
                    this.contiesList = this.contiesList.concat(await this.loadDestinationsList(destination.label, target));
                    this.contiesList.sort((a,b) => a.label.localeCompare(b.label));
                }
                else this.deleteFromSource(destination.type, destination.label);
                break;
            case "CONTY":
                if (destination.isCheck){
                    this.citiesList = this.citiesList.concat(await this.loadDestinationsList(destination.label, target));
                    this.citiesList.sort((a,b) => a.label.localeCompare(b.label));
                }
                else this.deleteFromSource(destination.type, destination.label);
                break;
            case "CITY":
                return;
        }
    }

    deleteFromSource(type: string, label: string) {
        switch (type) {
            case "COUNTRY":
                var states = this.statesList.filter(x => x.source == label);
                states.forEach(x => this.deleteFromSource("STATE", x.label));
                this.statesList = this.statesList.filter(x => x.source != label);
                break;
            case "STATE":
                var conties = this.contiesList.filter(x => x.source == label);
                conties.forEach(x => this.deleteFromSource("CONTY", x.label));
                this.contiesList = this.contiesList.filter(x => x.source != label);
                break;
            case "CONTY":
                var cities = this.citiesList.filter(x => x.source == label);
                cities.forEach(x => this.deleteFromSource("CITY", x.label));
                this.citiesList = this.citiesList.filter(x => x.source != label);
                break;
            case "CITY":
                return;
        }
    }

    goValidate() {
        var allList = this.countriesList;
        allList = allList.concat(this.statesList);
        allList = allList.concat(this.contiesList);
        allList = allList.concat(this.citiesList);

        var selectedList = allList.filter(x => x.isCheck);

        this.currentDialog.close({allList: allList, selectedList: selectedList})
    }
}