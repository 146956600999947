export class FilterDuration {
    public id: number;
    public label: string;
    public minValue: number;
    public maxValue: number;
    public order: number;

    constructor(id?: number, label?: string, minValue?: number, maxValue?: number, order?: number)

    constructor(id?: number, label?: string, minValue?: number, maxValue?: number, order?: number) {
        this.id = id;
        this.label = label;
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.order = order;
    }
}
