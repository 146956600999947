import { Injectable } from "@angular/core";
import { HttpRequestMethods } from "../shared/functions/http-request-methods.class";
import { User } from "../dto/user";
import { UtilsFunctions } from "../shared/functions/utils-functions";

@Injectable()
export class UserService extends HttpRequestMethods {

    update(uUser: User) {
        uUser.gender = UtilsFunctions.StringToBoolean(uUser.gender);
        
        return this.patch("user", uUser);
    }
    async updatePassword(lastPassword: string, newPassword: string, confirmPassword) {
        return await this.patch("user/password", { lastPassword: lastPassword, newPassword: newPassword, confirmPassword: confirmPassword });
    }

    async renewEmail(email: string){
        return await this.post(`user/renew/email?email=${email}`, null);
    }

    async forgetPassword(email: string){
        return await this.post(`user/password/forget?email=${email}`, null);
    }

    async resetPassword(email: string, token: string, password: string, confirmPassword: string){
        var formData = new FormData();
        formData.append("EMAIL", JSON.stringify(email));
        formData.append("PASWORD", JSON.stringify(password));
        formData.append("CONFIRM_PASSWORD", JSON.stringify(confirmPassword));
        formData.append("TOKEN", JSON.stringify(token));

        return await this.postFile(`user/password/new`, formData);
    }
}