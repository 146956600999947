import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailDialog } from 'src/dialogs/email/email';
import { ErrorMessage } from 'src/dto/errorClass/error';
import { environment } from 'src/environments/environment';
import { AnonymousService } from 'src/services/anonymous-service';
import { UserService } from 'src/services/user-service';
import { ErrorModalComponent } from 'src/shared/error-modal/error-modal.component';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'email-validation',
    templateUrl: './email-validation.view.html',
    styleUrls: ['./email-validation.view.scss']
})
export class EmailValidationView implements OnInit {
    isLoading = false;

    email: string;
    token: string;

    result = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private anonymousSvc: AnonymousService,
        public dialog: MatDialog,
        private userSvc: UserService,
        private _snackBar: MatSnackBar,
    ) { }

    async ngOnInit() {
        this.email = this.route.snapshot.paramMap.get('email');
        this.token = this.route.snapshot.paramMap.get('token');

        if(!isNullOrUndefined(this.email) && !isNullOrUndefined(this.token))
            await this.validationEmail();
    }


    async validationEmail() {
        this.isLoading = true;
        try {
            await this.anonymousSvc.validationEmail(this.email, this.token);
            this.isLoading = false;
            this.result = 1;
            await new Promise(resolve => setTimeout(resolve, 5000));
            this.router.navigate(['home']);
        }
        catch (ex) {
            this.result = -1;
        }
        this.isLoading = false;
    }

    sendNewValidation() {
        const dialogCard = this.dialog.open(EmailDialog, {
            width: '30%',
            height: '25rem',
            panelClass: 'mat-dialog-template',
            autoFocus: false
        });

        dialogCard.afterClosed().subscribe(async rslt => {
            if (!isNullOrUndefined(rslt)) {
                this.isLoading = true;
                try {
                    await this.userSvc.renewEmail(rslt);
                    this.result = 2;
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    this.router.navigate(['home']);
                }
                catch (ex) {
                    this._snackBar.openFromComponent(ErrorModalComponent, {
                        duration: environment.errorDuration,
                        data: new ErrorMessage(-1, ex.error.code)
                    });
                }
                this.isLoading = true;
            }
        });
    }

}
