import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessage } from 'src/dto/errorClass/error';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/services/user-service';
import { ErrorModalComponent } from 'src/shared/error-modal/error-modal.component';

@Component({
    selector: 'new-password',
    templateUrl: './new-password.view.html',
    styleUrls: ['./new-password.view.scss']
})
export class NewPasswordView {
    isLoading = false;

    email: string;
    token: string;
    password: string;
    confirmPassword: string;

    hideNewPassword = true;
    hideNewPassword2 = true;

    formGroup = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _snackBar: MatSnackBar,
        private userSvc: UserService
    ) { }

    async ngOnInit() {
        this.email = this.route.snapshot.paramMap.get('email');
        this.token = this.route.snapshot.paramMap.get('token');
    }

    async resetPassword(){
        if(this.formGroup.invalid){
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Veuillez remplir tous les champs")
            });
        }

        this.isLoading = true;
        try{
            await this.userSvc.resetPassword(this.email, this.token, this.password, this.confirmPassword);
            
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(1, "Votre mot de passe a été changé avec succés !")
            });
            
            this.router.navigate(['home']);
        }
        catch(ex){
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, ex.error.code)
            });
        }
        this.isLoading = false;
    }
}