import { ErrorMessage } from 'src/dto/errorClass/error';
import { UserService } from './../../services/user-service';
import { User } from 'src/dto/user';
import { Component, Injectable, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ErrorModalComponent } from 'src/shared/error-modal/error-modal.component';

export interface DialogData {
    user: User;
}

@Component({
    selector: 'edit-user-password',
    templateUrl: './password.html',
    styleUrls: ['./password.scss']
})

@Injectable()
export class EditUserPasswordDialog {
    isLoading = false;

    currentPasswordFormControl = new FormControl('', [Validators.required]);
    newPasswordFormControl = new FormControl('', [Validators.required]);
    confirmPasswordFormControl = new FormControl('', [Validators.required]);

    currentPassowrd: string;
    newPassword: string;
    confirmPassword: string;

    hideCurrentPassword = "password"
    hideNewPassword = "password"

    constructor(public currentDialog: MatDialogRef<EditUserPasswordDialog>,
        private _snackBar: MatSnackBar,
        private userSvc: UserService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    async save(){
        if(this.controlData() == false)
            return;

        this.isLoading = true;
        try{
            await this.userSvc.updatePassword(this.currentPassowrd, this.newPassword, this.confirmPassword);
            this.currentDialog.close();
        }
        catch(exception){
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, exception.error.code)
            });
        }
        this.isLoading = false;
    }

    private controlData(){
        if(this.newPassword != this.confirmPassword){
            this.newPasswordFormControl.setErrors({'incorrect': true});
            this.confirmPasswordFormControl.setErrors({'incorrect': true});

            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Vos nouveaux mots de passe ne correspondent pas")
            });
            return false;
        }

        return true;
    }
}