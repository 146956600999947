import { EditUserPasswordDialog } from '../../dialogs/password/password';
import { Component, Injectable, Input } from "@angular/core";
import { User } from "../../dto/user";
import { DialogCardComponent } from "../../shared/dialog-card/dialog-card.component";
import { MatDialog } from "@angular/material/dialog";
import { isNullOrUndefined } from "util";
import { UtilsFunctions } from "../../shared/functions/utils-functions";
import { Router } from "@angular/router";
import { StayService } from 'src/services/stay-service';
import { Stay } from 'src/dto/stay';
import { DConnexion } from '../../dto/dialogClass/connexion';
import { Structure } from 'src/dto/structure';
import { sideMenu } from 'src/dto/sideMenu';



@Component({
    selector: 'account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})

@Injectable()
export class AccountComponent {
    isLoading = false;

    currentUser: User;
    currentStructure: Structure;

    itemSelected: sideMenu;
    staysList = new Array<Stay>();
    displayStyle = "BLOCK";
    //subMenuIsOpen = false;
    @Input() subMenuIsOpen: boolean;


    constructor(public dialog: MatDialog, private router: Router, public staySvc: StayService) { }

    async ngOnInit() {
        this.currentUser = UtilsFunctions.SessionGetCurrentUser();
        this.currentStructure = UtilsFunctions.SessionGetCurrentStructure();

        if (this.currentUser == null)
            this.router.navigate(['home']);

        this.isLoading = true;
        if (!isNullOrUndefined(JSON.parse(sessionStorage.getItem("LASTSTAYLIST")))) {
            this.staysList = JSON.parse(sessionStorage.getItem("LASTSTAYLIST"))
        }
        else {
            this.staysList = await this.staySvc.listStays();
        }
        this.isLoading = false;
    }

    updateList(list: Array<Stay>) {
        this.staysList = list;
    }

    editPassword() {
        const dialogCard = this.dialog.open(EditUserPasswordDialog, {
            position: { top: '0rem', right: '0rem' },
            data: {
                user: this.currentUser
            },
            autoFocus: false
        });
        dialogCard.afterClosed().subscribe(rslt => {
            if (!isNullOrUndefined(rslt)) {
                this.currentUser = rslt

            }
        });
    }

    openDialogCard(item: string): void {
        const dialogCard = this.dialog.open(DialogCardComponent, {
            width: '30%',
            data: { item: item, currentStructure: this.currentUser }
        });

        dialogCard.afterClosed().subscribe(rslt => {
            if (!isNullOrUndefined(rslt)) {
                this.currentUser = rslt

            }
        });
    }

    addStay() {
        if (this.currentUser == null) {
            var data = new DConnexion(null, null, null);
            const dialogCard = this.dialog.open(DialogCardComponent, {
                width: '40%',
                data: { item: "Authentification", connexion: data }
            });
            dialogCard.afterClosed().subscribe(async rslt => {
                if (!isNullOrUndefined(rslt)) {
                    this.currentUser = UtilsFunctions.SessionGetCurrentUser();
                    this.currentStructure = UtilsFunctions.SessionGetCurrentStructure();

                    if (this.currentUser.profil == User.PROFIL_DIRECTOR || this.currentUser.profil == User.PROFIL_COLLABORATEUR) {
                        this.router.navigate(["edit-stay"]);
                    }
                }
            });
        }

        if (this.currentUser.profil == User.PROFIL_DIRECTOR || this.currentUser.profil == User.PROFIL_COLLABORATEUR) {
            this.router.navigate(["edit-stay"]);
        }
    }

    logOut() {
        sessionStorage.setItem("TOKEN", null);
        sessionStorage.setItem("USER", null);
        sessionStorage.setItem("STRUCTURE", null);
        sessionStorage.setItem("HOMECATEGORY", "");
        this.router.navigate(["/"]);
        this.refreshUserConnected();
    }

    refreshUserConnected() {
        this.currentUser = UtilsFunctions.SessionGetCurrentUser();
    }

    changeMenu(menu: sideMenu) {
        this.itemSelected = menu;
        sessionStorage.setItem("CURRENT_MENU", JSON.stringify(this.itemSelected));
    }
}