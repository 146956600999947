import { ErrorMessage } from 'src/dto/errorClass/error';
import { UserService } from './../../services/user-service';
import { User } from 'src/dto/user';
import { Component, Injectable, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ErrorModalComponent } from 'src/shared/error-modal/error-modal.component';

@Component({
    selector: 'email-dialog',
    templateUrl: './email.html',
    styleUrls: ['./email.scss']
})

@Injectable()
export class EmailDialog {
    isLoading = false;

    email: string;

    formGroup = new FormGroup({
        email: new FormControl('', [Validators.required]),
    })

    constructor(
        public currentDialog: MatDialogRef<EmailDialog>
    ) { }

    save() {
        this.currentDialog.close(this.email);
    }
}