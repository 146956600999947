export class FilterStartDeparture {
    public value: string;
    public displayValue: string;
    public category: string;
    public isSelected: boolean;
    public order: number;

    constructor(value?: string, displayValue?: string, category?: string,
        isSelected?: boolean, order?: number)

    constructor(value: string, displayValue: string, category: string,
        isSelected: boolean, order: number) {
        this.value = value;
        this.displayValue = displayValue;
        this.category = category;
        this.isSelected = isSelected;
        this.order = order;
    }
}