import { Injectable, Component } from "@angular/core";
import { MatDialog, MatDialogRef, MatSnackBar } from "@angular/material";
import { ErrorMessage } from "src/dto/errorClass/error";
import { environment } from "src/environments/environment";
import { UserService } from "src/services/user-service";
import { ErrorModalComponent } from "src/shared/error-modal/error-modal.component";
import { isNullOrUndefined } from "util";
import { EmailDialog } from "../email/email";

@Component({
    selector: 'welcome-dialog',
    templateUrl: './welcome.dialog.html',
    styleUrls: ['./welcome.dialog.scss']
})


@Injectable()
export class WelcomeDialog {
    isLoading = false;

    result = 0;

    constructor(
        public dialog: MatDialog,
        private userSvc: UserService,
        private _snackBar: MatSnackBar,
        public currentDialog: MatDialogRef<WelcomeDialog>
    ) { }

    ngOnInit() { }

    sendNewValidation() {
        const dialogCard = this.dialog.open(EmailDialog, {
            width: '30%',
            height: '25rem',
            panelClass: 'mat-dialog-template',
            autoFocus: false
        });

        dialogCard.afterClosed().subscribe(async rslt => {
            if (!isNullOrUndefined(rslt)) {
                this.isLoading = true;
                try {
                    await this.userSvc.renewEmail(rslt);
                    this.result = 1;
                }
                catch (ex) {
                    this._snackBar.openFromComponent(ErrorModalComponent, {
                        duration: environment.errorDuration,
                        data: new ErrorMessage(-1, ex.error.code)
                    });
                }
                this.isLoading = true;
            }
        });
    }
}