import { isNull } from '@angular/compiler/src/output/output_ast';
import { Component, Injectable, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Activity } from 'src/dto/activity';
import { isNullOrUndefined } from 'util';

export interface DialogData {
    yearsList: Array<string>;
}

@Component({
    selector: 'filter-year',
    templateUrl: './years.html',
    styleUrls: ['./years.scss']
})

@Injectable()
export class FilterYearsDialog {

    yearTxt: string;
    tmp_yearsList: Array<string>;

    constructor(
        public currentDialog: MatDialogRef<FilterYearsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
        this.tmp_yearsList = JSON.parse(JSON.stringify(this.data.yearsList));
    }

    goValidate() {
        this.data.yearsList = this.tmp_yearsList;
        this.currentDialog.close(this.data);
    }

    addYear(){
        if(!isNullOrUndefined(this.yearTxt) && this.yearTxt != ""){
            this.tmp_yearsList.push(this.yearTxt);
            this.yearTxt = "";
        }
    }

    goKeyUp(event){
        if(event.code == "NumpadEnter" || event.code == "Enter")
            this.addYear();
    }

    deleteYear(year: string){
        this.tmp_yearsList = this.tmp_yearsList.filter(x => x != year);
    }
}