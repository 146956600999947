import { FilterDuration } from "src/dto/filters/filterDuration";
import { FilterOrder } from "src/dto/filters/filterOrder";
import { FilterStartDeparture } from "src/dto/filters/filterStartDeparture";
import { FilterYear } from "src/dto/filters/filterYear";

export class SharedSettings {

    public static listStartDepartureFilters(type: string) {
        switch (type) {
            case "MONTH":
                return [
                    new FilterStartDeparture("01", "Janvier", "MONTH", null, 1),
                    new FilterStartDeparture("02", "Février", "MONTH", null, 2),
                    new FilterStartDeparture("03", "Mars", "MONTH", null, 3),
                    new FilterStartDeparture("04", "Avril", "MONTH", null, 4),
                    new FilterStartDeparture("05", "Mai", "MONTH", null, 5),
                    new FilterStartDeparture("06", "Juin", "MONTH", null, 6),
                    new FilterStartDeparture("07", "Juillet", "MONTH", null, 7),
                    new FilterStartDeparture("08", "Août", "MONTH", null, 8),
                    new FilterStartDeparture("09", "Septembre", "MONTH", null, 9),
                    new FilterStartDeparture("10", "Octobre", "MONTH", null, 10),
                    new FilterStartDeparture("11", "Novembre", "MONTH", null, 11),
                    new FilterStartDeparture("12", "Décembre", "MONTH", null, 12),
                ]

            case "HOLIDAYS":
                return [
                    new FilterStartDeparture("10/11", "Vacances de la Toussaint (Octobre / Novembre)", "HOLIDAYS"),
                    new FilterStartDeparture("12/01", "Vacances de Noël (Décembre / Janvier)", "HOLIDAYS"),
                    new FilterStartDeparture("02/03", "Vacances d'hiver (Février / Mars)", "HOLIDAYS"),
                    new FilterStartDeparture("04/05", "Vacances de printemps (Avril / Mai)", "HOLIDAYS"),
                    new FilterStartDeparture("06/07/08", "Vacances d'été (Juin / Juillet / Août)", "HOLIDAYS")
                ]
        }
    }

    public static listDurationFilters() {
        return [
            new FilterDuration(2, "2 jours"),
            new FilterDuration(5, "5 jours"),
            new FilterDuration(7, "1 semaine"),
            new FilterDuration(14, "2 semaines"),
            new FilterDuration(21, "3 semaines"),
            new FilterDuration(31, "1 mois"),
            new FilterDuration(62, "2 mois"),
        ];
    }

    public static listOrderFilters() {
        return [
            new FilterOrder("PRICE_ASC", "Prix : ordre croissant"),
            new FilterOrder("PRICE_DESC", "Prix : ordre décroissant"),
            new FilterOrder("RECENTLY", "Offre récente"),
        ];
    }
}