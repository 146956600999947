export class Activity {
    public id: number;
    public label: string;
    public isSelected: boolean;

    constructor(id?: number, label?: string, isSelected?: boolean)

    constructor(id: number, label: string, isSelected: boolean) {
        this.id = id;
        this.label = label;
        this.isSelected = isSelected;
    }
}
