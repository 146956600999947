import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeView } from '../views/home/home.view';
import { AccountComponent } from '../views/profile/account.component';
import { FavoriteComponent } from '../components/favorite/favorite.component';
import { StayListView } from 'src/views/stay/list/stay-list.view';
import { EditStayView } from 'src/views/stay/edit/edit.view';
import { StayInformationView } from 'src/views/stay/information/stay-information.view';
import { StructuresView } from 'src/views/structures/structures.view';
import { EmailValidationView } from 'src/views/validation/email/email-validation.view';
import { NewPasswordView } from 'src/views/user/password/new-password.view';

const routes: Routes = [
    {
        path: 'home',
        component: HomeView
    },
    {
         path: 'account',
         component: AccountComponent
     },
    // {
    //     path: 'favorites',
    //     component: FavoriteComponent
    // },
    {
        path: 'stays-list',
        component: StayListView
    },
    {
        path: 'edit-stay',
        component: EditStayView
    },
    {
        path: 'stay/:stayId',
        component: StayInformationView
    },
    {
        path: 'structures',
        component: StructuresView
    },
    {
        path: 'user/validation-email/:token/:email',
        component: EmailValidationView
    },
    {
        path: 'user/password/new/:token/:email',
        component: NewPasswordView
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
