export class FilterDuration {
    public value: number;
    public displayValue: string;

    constructor(value?: number, displayValue?: string)

    constructor(value: number, displayValue: string) {
        this.value = value;
        this.displayValue = displayValue;
    }
}