import { Injectable } from "@angular/core";
import { HttpRequestMethods } from "../shared/functions/http-request-methods.class";
import { Stay } from "../dto/stay";
import { CreationObject } from "../shared/functions/creation-object";
import { Activity } from "../dto/activity";
import { StayConfig } from "../dto/parseClass/stayConfig";
import { Thematic } from "src/dto/thematic";
import { Tag } from "src/dto/tag";
import { isNullOrUndefined } from "util";
import { UtilsFunctions } from "src/shared/functions/utils-functions";
import { Filters } from "src/dto/filters";
import { Destination } from "src/dto/destination";

@Injectable()
export class StayService extends HttpRequestMethods {

    public staysList: Array<Stay>;

    async listStaysByStructure(structureId: number) {
        var rslt = await this.get("stay/structure/" + structureId);
        var rsltArray = rslt as string[];

        var staysList = new Array<Stay>();
        rsltArray.forEach(stay => {
            staysList.push(CreationObject.CreateStay(stay));
        })
        return staysList;
    }

    async listStays() {
        var rslt = await this.getWithoutToken("stay");
        var rsltArray = rslt as string[];

        var staysList = new Array<Stay>();
        rsltArray.forEach(stay => {
            staysList.push(CreationObject.CreateStay(stay));
        })
        return staysList;
    }

    async listStartCities() {
        var rslt =  await this.getWithoutToken("stay/start-cities");
        return rslt as string[];
    }

    update(stay: Stay, activities: Array<Activity>, thematics: Array<Thematic>) {
        var stayConfig = new StayConfig(
            stay,
            activities,
            thematics,
        );

        return this.patch("stay", stayConfig);
    }

    create(stay: Stay, structureId: number, newPicturesList: Array<any>) {
        stay.furtherInformationsList.forEach(i => {
            i.withTransport = UtilsFunctions.StringToBoolean(i.withTransport);
        })

        var formData = new FormData();
        formData.append("STRUCTURE_ID", JSON.stringify(structureId))
        formData.append("STAY", JSON.stringify(stay));

        var i = 1;
        if(newPicturesList.length > 0){
            newPicturesList.forEach(p => {
                formData.append(`PICTURE_${i}`, p);
                i++;
            })
        }

        return this.postFile("stay", formData);
    }

    remove(stays: Array<Stay>) {
        return this.post("stay/removeList", stays);
    }

    addToFavorite(stayId: number) {
        return this.post("stay/favorite/" + stayId, null);
    }

    removeToFavorite(stayId: number) {
        return this.delete("stay/favorite/" + stayId, null);
    }

    favoriteStaysList() {
        return this.get("stay/favorite");
    }

    async staysFiltered(filters: Filters) {
        filters.allDestinationsList = null;
        
        var rslt = await this.post("stay/filters", filters);
        var rsltTab = rslt as string[];

        var stayList = new Array<Stay>();
        rsltTab.forEach(stay => {
            stayList.push(CreationObject.CreateStay(stay));
        })

        return stayList;
    }

    async popularStays(){
        var rslt = await this.get("stay/populars");
        var rsltTab = rslt as string[];
        
        var stayList = new Array<Stay>();
        rsltTab.forEach(stay => {
            stayList.push(CreationObject.CreateStay(stay));
        })
        return stayList;
    }

    async lastMinutesStays(){
        return new Array<Stay>();
        var rslt = await this.get("stay/lastMinutes");
        var rsltTab = rslt as string[];

        var stayList = new Array<Stay>();
        rsltTab.forEach(stay => {
            stayList.push(CreationObject.CreateStay(stay));
        })

        return stayList;
    }

    async getStay(stayId: number){
        return CreationObject.CreateStay(await this.get(`stay/${stayId}`));
    }

    async listDestinations(source: string, target: string) {
        var rslt = await this.getWithoutToken(`stay/destinations?source=${source}&target=${target}`);
        var rsltArray = rslt as string[];

        var destinationsList = new Array<Destination>();
        rsltArray.forEach(destination => {
            destinationsList.push(CreationObject.CreateDestination(destination));
        })
        return destinationsList;
    }
}