import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { PlaceSuggestion } from 'src/components/ui/address-autocomplete/address-autocomplete.component';
import { FilterDateDepartureDialog } from 'src/dialogs/filters/date-departure/date-departure';
import { FilterDestinationDialog } from 'src/dialogs/filters/destination/destination';
import { Activity } from 'src/dto/activity';
import { Destination } from 'src/dto/destination';
import { Filters } from 'src/dto/filters';
import { FilterDuration } from 'src/dto/filterDuration';
import { FilterStartDeparture } from 'src/dto/filters/filterStartDeparture';
import { FilterYear } from 'src/dto/filters/filterYear';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Structure } from 'src/dto/structure';
import { Thematic } from 'src/dto/thematic';
import { ActivityService } from 'src/services/activity-service';
import { StayService } from 'src/services/stay-service';
import { ThematicService } from 'src/services/thematic-service';
import { SharedSettings } from 'src/shared/functions/shared-settings';
import { isNullOrUndefined } from 'util';
import { StructureService } from '../../../services/structure-service';
import { FilterActivityDialog } from 'src/dialogs/filters/activity/activity';
import { FilterStructureDialog } from 'src/dialogs/filters/structure/structure';
import { FilterYearsDialog } from 'src/dialogs/filters/years/years';
import { FilterDurationService } from 'src/services/filter-duration-service';




@Component({
    selector: 'searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss']
})


@Injectable()
export class SearchbarComponent {
    isLoading = false;

    @Input() imageUrl: string;
    @Input() title: string;
    @Input() heightSize: string;

    @Output() searchAction = new EventEmitter<string>();

    moreFilters = false;
    filters = new Filters();

    listStartDepartureFilters = new Array<FilterStartDeparture>();
    dateSelected: Date;
    thematicList = new Array<Thematic>();
    durationList = new Array<FilterDuration>();
    structureSearchList = new Array<Structure>();
    startCitiesList = new Array<string>();
    yearsSearchList = new Array<string>();

    dateDepartureTxt: string;
    destinationsTxt: string;
    activitiesTxt: string;
    structuresTxt: string;
    yearsTxt: string;

    editSearchYear = false;

    myControl = new FormControl('');
    options: Array<Structure> = new Array<Structure>();
    filteredOptions: Observable<Array<Structure>>;


    activityList = new Array<Activity>();

    stayCity: string;
    structure: Array<Structure>;
    typesOfMois: { value?: number, label?: string } = {};


    constructor(
        private thematicSvc: ThematicService,
        private staySvc: StayService,
        private activitySvc: ActivityService,
        private structureSvc: StructureService,
        public dialog: MatDialog,
        public dialog2: MatDialog,
        public datepipe: DatePipe,
        public filterDurationSvc: FilterDurationService
    ) { }

    async ngOnInit() {
        // Initialisation des listes des filtres

        //Mois de départ
        this.listStartDepartureFilters = this.listStartDepartureFilters.concat(SharedSettings.listStartDepartureFilters("MONTH"));
        // Période de départ
        this.listStartDepartureFilters = this.listStartDepartureFilters.concat(SharedSettings.listStartDepartureFilters("HOLIDAYS"));
        // Thématiques
        await this.loadThematicsList()
        //Durée
        await this.loadFilterDuration();
        //Activités
        await this.loadActivitiesList();
        //Structure
        await this.loadStructuresList();
        //Destinations
        await this.loadDestinationsList();
        //Ville de départ
        await this.loadStartCitiesList();

        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );

        // Récupération des filtres si déjà sélectionné
        this.initFilters();
    }

    initFilters() {
        if (!isNullOrUndefined(sessionStorage.getItem("FILTERS")) && sessionStorage.getItem("FILTERS") != "null") {
            this.filters = JSON.parse(sessionStorage.getItem("FILTERS"));

            if (!isNullOrUndefined(this.filters.startDepartureList) && this.filters.startDepartureList.length > 0) {
                var listStartDepartures = this.filters.startDepartureList.filter(x => x.isSelected).map(x => x.displayValue);
                this.dateDepartureTxt = listStartDepartures.join(", ");

                this.listStartDepartureFilters.forEach(x => {
                    if ((this.filters.startDepartureList.filter(y => y.value == x.value)).length > 0)
                        x.isSelected = true;
                })
            }

            if (!isNullOrUndefined(this.filters.startDepartureDate) && listStartDepartures.length == 0) {
                this.dateSelected = new Date(this.filters.startDepartureDate);
                this.dateDepartureTxt = this.datepipe.transform(this.dateSelected, 'dd/MM/yyyy');
            }

            if (!isNullOrUndefined(this.filters.destinationsList) && this.filters.destinationsList.length > 0) {
                this.destinationsTxt = this.filters.destinationsList.map(x => x.label).join(", ");
            }

            if (!isNullOrUndefined(this.filters.activitiesList) && this.filters.activitiesList.length > 0) {
                this.activitiesTxt = this.filters.activitiesList.map(x => x.label).join(", ");
                this.activityList.forEach(x => {
                    if ((this.filters.activitiesList.filter(y => y.id == x.id)).length > 0)
                        x.isSelected = true;
                })
            }

            if (!isNullOrUndefined(this.filters.structuresList) && this.filters.structuresList.length > 0) {
                this.structuresTxt = this.filters.structuresList.map(x => x.name).join(", ");
                this.structureSearchList.forEach(x => {
                    if ((this.filters.structuresList.filter(y => y.id == x.id)).length > 0)
                        x.isSelected = true;
                })
            }

            if (!isNullOrUndefined(this.filters.yearsList) && this.filters.yearsList.length > 0) {
                this.yearsTxt = this.filters.yearsList.map(x => x).join(", ");
                this.yearsSearchList = this.filters.yearsList;
                if (this.yearsSearchList.length > 0)
                    this.yearsTxt = `${this.yearsTxt} ans`;
            }
        }
    }

    async loadThematicsList() {
        this.isLoading = true;

        this.thematicList = await this.thematicSvc.listThematic();
        this.isLoading = false;
    }

    async loadActivitiesList() {
        this.isLoading = true;
        this.activityList = await this.activitySvc.listActivity();
        this.isLoading = false;
    }

    async loadStructuresList() {
        this.isLoading = true;
        this.structureSearchList = await this.structureSvc.listActiveStructure();
        this.isLoading = false;
    }

    async loadDestinationsList() {
        this.isLoading = true;
        this.filters.allDestinationsList = await this.staySvc.listDestinations("", "COUNTRY");
        this.isLoading = false;
    }

    async loadFilterDuration() {
        this.isLoading = true;
        this.durationList = await this.filterDurationSvc.listFilterDuration();
        this.isLoading = false;
    }

    async loadStartCitiesList() {
        this.isLoading = true;
        this.startCitiesList = await this.staySvc.listStartCities();
        this.isLoading = false;
    }



    compareThematic(thematic1: Thematic, thematic2: Thematic) {
        return thematic1 && thematic2 ? thematic1.id === thematic2.id : thematic1 === thematic2;
    }

    compareActivity(activity1: Activity, activity2: Activity) {
        return activity1 && activity2 ? activity1.id === activity2.id : activity1 === activity2;
    }

    compareStructure(structure1: Structure, structure2: Structure) {
        return structure1 && structure2 ? structure1.id === structure2.id : structure1 === structure2;
    }

    compareYear(year1: FilterYear, year2: FilterYear) {
        return year1 && year2 ? year1.value === year2.value : year1 === year2;
    }

    compareDuration(duration1: FilterDuration, duration2: FilterDuration) {
        return duration1 && duration2 ? duration1.id === duration2.id : duration1 === duration2;
    }

    compareStartCity(city1: string, city2: string) {
        return city1 === city2;
    }

    changeStayCityPeriod(place: PlaceSuggestion) {
        this.stayCity = place.data.city;
    }

    openFilterDialog(type: string) {
        switch (type) {
            case "DATE_DEPARTURE":
                const dialogCard = this.dialog.open(FilterDateDepartureDialog, {
                    data: {
                        listStartDepartureFilters: this.listStartDepartureFilters,
                        dateSelected: this.dateSelected
                    }
                });

                dialogCard.afterClosed().subscribe(async rslt => {
                    if (!isNullOrUndefined(rslt)) {
                        var filter = rslt.listStartDepartureFilters.filter(x => x.isSelected).map(x => x.displayValue);

                        if (!isNullOrUndefined(rslt.dateSelected) && filter.length == 0) {
                            this.dateSelected = new Date(rslt.dateSelected);
                            filter.push(this.datepipe.transform(this.dateSelected, 'dd/MM/yyyy'));
                        }

                        this.dateDepartureTxt = filter.join(", ");

                        this.filters.startDepartureList = this.listStartDepartureFilters.filter(x => x.isSelected);
                        this.filters.startDepartureDate = this.dateSelected;
                    }
                });
                break;

            case "DESTINATIONS":
                const dialogCard2 = this.dialog.open(FilterDestinationDialog, {
                    width: '50%',
                    data: {
                        displayList: this.filters.allDestinationsList
                    }
                });

                dialogCard2.afterClosed().subscribe(async rslt => {
                    if (!isNullOrUndefined(rslt) && !isNullOrUndefined(rslt.selectedList)) {
                        this.filters.allDestinationsList = rslt.allList;

                        this.filters.destinationsList = new Array<Destination>();
                        rslt.selectedList.forEach(x => {
                            if (rslt.selectedList.filter(d => d.source == x.label).length == 0)
                                this.filters.destinationsList.push(x);
                        })
                        this.destinationsTxt = this.filters.destinationsList.map(x => x.label).join(", ");
                    }
                });
                break;

            case "ACTIVITIES":
                const dialogCard3 = this.dialog.open(FilterActivityDialog, {
                    width: '50%',
                    height: '60%',
                    data: {
                        activitiesList: this.activityList
                    }
                });

                dialogCard3.afterClosed().subscribe(async rslt => {
                    if (!isNullOrUndefined(rslt) && !isNullOrUndefined(rslt.activitiesList)) {
                        this.filters.activitiesList = rslt.activitiesList.filter(x => x.isSelected);
                        this.activitiesTxt = this.filters.activitiesList.map(x => x.label).join(", ");
                    }
                });
                break;

            case "STRUCTURES":
                const dialogCard4 = this.dialog.open(FilterStructureDialog, {
                    width: '50%',
                    height: '60%',
                    data: {
                        structuresList: this.structureSearchList
                    }
                });

                dialogCard4.afterClosed().subscribe(async rslt => {
                    if (!isNullOrUndefined(rslt) && !isNullOrUndefined(rslt.structuresList)) {
                        this.filters.structuresList = rslt.structuresList.filter(x => x.isSelected);
                        this.structuresTxt = this.filters.structuresList.map(x => x.name).join(", ");
                    }
                });
                break;

            case "YEARS":
                const dialogCard5 = this.dialog.open(FilterYearsDialog, {
                    width: '30%',
                    height: '40%',
                    data: {
                        yearsList: this.yearsSearchList
                    }
                });

                dialogCard5.afterClosed().subscribe(async rslt => {
                    if (!isNullOrUndefined(rslt) && !isNullOrUndefined(rslt.yearsList)) {
                        this.yearsSearchList = rslt.yearsList;
                        this.filters.yearsList = rslt.yearsList;
                        this.yearsTxt = this.filters.yearsList.map(x => x).join(", ");

                        if (this.filters.yearsList.length > 0)
                            this.yearsTxt = `${this.yearsTxt} ans`;
                    }
                });
                break;
        }
    }

    search() {
        sessionStorage.setItem("FILTERS", JSON.stringify(this.filters));
        this.searchAction.emit('OK');
    }

    launchSearchByKeyWords(event) {
        if (event.code == "NumpadEnter" || event.code == "Enter")
            this.search();
    }

    private _filter(value: string): Array<Structure> {
        const filterValue = value.toLowerCase();

        return this.structureSearchList.filter(s => s.name.toLowerCase().includes(filterValue));
    }

    initSearch() {
        this.filters.keywords = "";

        this.filters.startDepartureList = new Array<FilterStartDeparture>();
        this.filters.startDepartureDate = null;
        this.dateDepartureTxt = "";

        this.filters.durationsList = new Array<FilterDuration>();

        this.filters.yearsList = new Array<string>();
        this.yearsTxt = "";

        this.filters.startCitiesList = new Array<string>();

        this.filters.destinationsList = new Array<Destination>();
        this.filters.allDestinationsList.forEach(d => {
            d.isCheck = false;
            d.isExpand = false;
        })
        this.destinationsTxt = "";

        this.filters.thematics = new Array<Thematic>();

        this.filters.activitiesList.forEach(a => a.isSelected = false);
        this.activitiesTxt = "";

        this.filters.structuresList.forEach(s => s.isSelected = false);
        this.structuresTxt = "";

        this.search();
    }
}



