import { Injectable, Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, MatStepper, MAT_DIALOG_DATA } from "@angular/material";
import { PlaceSuggestion } from "src/components/ui/address-autocomplete/address-autocomplete.component";
import { ErrorMessage } from "src/dto/errorClass/error";
import { Structure } from "src/dto/structure";
import { User } from "src/dto/user";
import { environment } from "src/environments/environment";
import { AnonymousService } from "src/services/anonymous-service";
import { UserService } from "src/services/user-service";
import { ErrorModalComponent } from "src/shared/error-modal/error-modal.component";
import { isNullOrUndefined } from "util";
import { EmailDialog } from "../email/email";
import { WelcomeDialog } from "../welcome/welcome.dialog";

@Component({
    selector: 'register-dialog',
    templateUrl: './register.dialog.html',
    styleUrls: ['./register.dialog.scss']
})


@Injectable()
export class RegisterDialog {
    isLoading = false;
    hide = "password";
    confirmHide = "password";

    user: User;
    structure: Structure
    place: any;

    adressStatut = "";

    organisationFormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        siret: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    userFormGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required])
    })

    welcome: boolean = false;

    Items = [
        { id: 1, title: "Ouvert à tous" },
        { id: 2, title: "limités à un secteur géographique" },
        { id: 3, title: "Pour un public spécifique" },
        { id: 4, title: "Séjours de vacances adaptés" },
        { id: 5, title: "Séjours linguistique" },
        { id: 6, title: "Stage sportifs" },
        { id: 7, title: "Dernières" },
        { id: 8, title: "Colonies de vacances" }

    ]

    constructor(
        public dialog: MatDialog,
        public currentDialog: MatDialogRef<RegisterDialog>,
        private _snackBar: MatSnackBar,
        private anonymousSvc: AnonymousService,
        private userSvc: UserService
    ) {
        this.user = new User();
        this.structure = new Structure();
    }

    ngOnInit() { }

    async register() {

        if (!this.checkForm())
            return;

        this.isLoading = true;
        try {
            await this.anonymousSvc.registration(this.user, this.structure);
            this.currentDialog.close(true);
        }
        catch (ex) {
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, ex.error.code)
            });
        }
        this.isLoading = false;
    }

    sendNewValidation() {
        const dialogCard = this.dialog.open(EmailDialog, {
            width: '30%',
            height: '25rem',
            panelClass: 'mat-dialog-template',
            autoFocus: false
        });

        dialogCard.afterClosed().subscribe(async rslt => {
            if (!isNullOrUndefined(rslt)) {
                this.isLoading = true;
                try {
                    await this.userSvc.renewEmail(rslt);
                    this.currentDialog.close();
                }
                catch (ex) {
                    this._snackBar.openFromComponent(ErrorModalComponent, {
                        duration: environment.errorDuration,
                        data: new ErrorMessage(-1, ex.error.code)
                    });
                }
                this.isLoading = true;
            }
        });
    }

    changeStructureAddress(place: PlaceSuggestion) {
        this.place = place;
        this.structure.street = `${place.data.housenumber} ${place.data.street}`;
        this.structure.postCode = place.data.postcode;
        this.structure.city = place.data.city;
        this.structure.state = place.data.state;
        this.structure.country = place.data.country;
        this.structure.latitude = place.data.lat.toString();
        this.structure.longitude = place.data.lon.toString();
    }

    checkForm() {
        if (!this.userFormGroup.valid) {
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Veuillez remplir tous les champs obligatoires")
            });
            return false;
        }

        if (this.user.profil == User.PROFIL_DIRECTOR) {
            if (!this.organisationFormGroup.valid) {
                this._snackBar.openFromComponent(ErrorModalComponent, {
                    duration: environment.errorDuration,
                    data: new ErrorMessage(-1, "Veuillez remplir tous les champs obligatoires")
                });
                return false;
            }
        }

        if (this.user.confirmPassword != this.user.password) {
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Vos mots de passe ne correspondent pas")
            });
            return false;
        }

        if (this.user.password.length < 8
            || this.user.password.match(/[0-9]/) == null
            || this.user.password.match(/[a-z]/) == null
            || this.user.password.match(/[A-Z]/) == null) {
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Votre mot de passe doit contenir : au moins 8 caractères, une majuscule et une minuscule")
            });
            return false;
        }


        return true;
    }

    goStep2(stepper: MatStepper) {
        if (!this.organisationFormGroup.valid || this.adressStatut == "INVALID")
            return;

        if (this.adressStatut == "VALID"
            && (isNullOrUndefined(this.structure.longitude) || isNullOrUndefined(this.structure.latitude)
                || this.structure.longitude == "" || this.structure.latitude == "")) {
            this._snackBar.openFromComponent(ErrorModalComponent, {
                duration: environment.errorDuration,
                data: new ErrorMessage(-1, "Veuillez sélectionner une adresse postale valide")
            });
            return;
        }
        stepper.next();
    }
}